export default class AboutPageEvent {
  constructor() {
    this.init();
  }
  init() {
    if (!document.querySelector('.js-AboutClip')) return false;
    const KV = document.querySelector('.js-AboutClip');
    let sideVal = 20;
    let roundVal = 100;
    // const KVImage = KV.querySelector('img');
    window.addEventListener('scroll', () => {
      ScrollEv();
    });
    ScrollEv();

    if (window.innerWidth < 768) {
      sideVal = 5;
      roundVal = 50;
    }

    KV.style.clipPath =
      'inset(0 ' +
      sideVal +
      '% 0 ' +
      sideVal +
      '% round ' +
      roundVal +
      'vh ' +
      roundVal +
      'vh 0 0)';

    function ScrollEv() {
      let Val = window.pageYOffset / window.innerHeight;
      let round = roundVal - Val * 100;
      let side = sideVal - 80 * Val;
      if (round < 10) {
        round = 10;
      }
      if (side < 0) {
        side = 0;
      }
      // KVImage.style.transform = 'translateY(' + Val * 10 + '%)';
      KV.style.backgroundPosition = 'center ' + Val * 20 + '%';
      KV.style.clipPath =
        'inset(0 ' + side + '% 0% ' + side + '% round ' + round + 'vh ' + round + 'vh 0 0)';
    }
  }
}
