// import Swiper JS
import Swiper from 'swiper/bundle';

export default class SwiperEvent {
  constructor() {
    this.init();
  }
  init() {
    if (document.querySelector('.timeTableSwiper')) {
      const topWelComeTimeTableLines = document.querySelectorAll('.m_welComeTimeTableLineOne');
      const timeTableSwiper = new Swiper('.timeTableSwiper', {
        // Optional parameters
        loop: true,
        speed: 1500,
        autoplay: {
          delay: 5000,
          // reverseDirection: true,
          disableOnInteraction: false,
        },
        // slidesPerView: 1,
        // centeredSlides: true,
        effect: 'creative',
        creativeEffect: {
          prev: {
            shadow: true,
            // 前のスライドのZ軸（奥行）対して(-400px)を設定しています
            translate: ['-20%', 0, -1],
          },
          next: {
            // 次のスライドのX軸（左右）対して(100%)を設定しています
            translate: ['100%', 0, 0],
          },
        },
        navigation: {
          nextEl: '.c_welComeTimeTableLineNavR',
          prevEl: '.c_welComeTimeTableLineNavL',
        },
      });
      timeTableSwiper.on('activeIndexChange', function (e) {
        // console.log(timeTableSwiper.realIndex);
        document.querySelector('.m_welComeTimeTableLineOne.Act').classList.remove('Act');
        topWelComeTimeTableLines[timeTableSwiper.realIndex].classList.add('Act');
      });

      const observer = new IntersectionObserver(
        (entries) => {
          // entriesは監視対象すべてが入っているリスト
          for (const e of entries) {
            // isIntersecting プロパティは交差しているかどうかの真偽値
            // viewport に交差し、入ったときに isIntersecting === true、出たときに false になる
            if (e.isIntersecting) {
              // console.log(e.target.dataset.name);
              const dotNavOneAct = document.querySelectorAll('.p_dotNavOne');
              for (let i = 0; i < dotNavOneAct.length; i++) {
                dotNavOneAct[i].classList.remove('Act');
              }
              timeTableSwiper.autoplay.start();
            } else {
              timeTableSwiper.autoplay.stop();
            }
          }
        },
        {
          root: null, //ターゲットが見えるかどうかを確認するためのビューポート, nullはブラウザビューポート
          rootMargin: '0px', //rootの周りのマージンの値
          threshold: 0, //どの程度表示されたか
        }
      );

      observer.observe(document.querySelector('.timeTableSwiper'));
    }
    if (document.querySelector('.recommendSwiper')) {
      const recommendSwiper = new Swiper('.recommendSwiper', {
        // Optional parameters
        loop: true,
        speed: 1500,
        spaceBetween: 20,
        slidesPerView: 1.17,
        centeredSlides: true,
        navigation: {
          nextEl: '.m_slideBottomNavR',
          prevEl: '.m_slideBottomNavL',
        },
        breakpoints: {
          // 768px以上の場合
          768: {
            spaceBetween: 20,
            slidesPerView: 2.34,
          },
        },
      });
    }

    if (document.querySelector('.aboutCardSwiper')) {
      const aboutSwiper = new Swiper('.aboutCardSwiper', {
        // Optional parameters
        loop: true,
        speed: 1500,
        spaceBetween: 16,
        slidesPerView: 1.17,
        centeredSlides: true,
        autoplay: {
          delay: 2600,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: '.m_aboutCardNavR',
          prevEl: '.m_aboutCardNavL',
        },
        breakpoints: {
          // 768px以上の場合
          768: {
            slidesPerView: 2.1,
            spaceBetween: 20,
          },
        },
      });
    }

    if (document.querySelector('.mapSwiper')) {
      const mapSwiper = new Swiper('.mapSwiper', {
        // Optional parameters
        loop: true,
        speed: 1500,
        spaceBetween: 16,
        slidesPerView: 1.1,
        breakpoints: {
          // 768px以上の場合
          768: {
            slidesPerView: 1.18,
            spaceBetween: 20,
          },
        },
      });
    }

    if (document.querySelector('.eventPickUpSwiper')) {
      const pickUpSwiper = new Swiper('.eventPickUpSwiper', {
        loop: true,
        speed: 1500,
        spaceBetween: 14,
        slidesPerView: 1.45,
        centeredSlides: true,
        navigation: {
          nextEl: '.m_slideBottomNavR',
          prevEl: '.m_slideBottomNavL',
        },

        breakpoints: {
          // 768px以上の場合
          768: {
            spaceBetween: 20,
            slidesPerView: 2.5,
          },
        },
      });
    }

    if (document.querySelector('.menuCardsSwiper')) {
      const menuSwiper = new Swiper('.menuCardsSwiper', {
        loop: true,
        speed: 1600,
        spaceBetween: 16,
        slidesPerView: 1.6,
        centeredSlides: true,
        autoplay: {
          delay: 2600,
          disableOnInteraction: false,
        },
        breakpoints: {
          // 768px以上の場合
          768: {
            spaceBetween: 20,
            slidesPerView: 4.3,
          },
        },
      });

      // menuSwiper.addEventListener('mouseenter', function () {
      //   if (!window.matchMedia('(max-width: 767px)').matches) {
      //     menuSwiper.autoplay.stop();
      //     // console.log('slider stopped');
      //   }
      // });
      // menuSwiper.addEventListener('mouseleave', function () {
      //   if (!window.matchMedia('(max-width: 767px)').matches) {
      //     menuSwiper.autoplay.start();
      //     // console.log('slider started again');
      //   }
      // });
    }

    if (document.querySelector('.otherNewsSwiper')) {
      let otherSwiper, swiperbool;
      const breakPoint = 768;
      if (breakPoint < window.innerWidth) {
        swiperbool = false;
      } else {
        createSwiper();
        swiperbool = true;
      }
      window.addEventListener(
        'resize',
        () => {
          if (breakPoint < window.innerWidth && swiperbool) {
            otherSwiper.destroy(false, true);
            swiperbool = false;
          } else if (breakPoint >= window.innerWidth && !swiperbool) {
            createSwiper();
            swiperbool = true;
          }
        },
        false
      );
      function createSwiper() {
        otherSwiper = new Swiper('.otherNewsSwiper', {
          loop: true,
          speed: 1500,
          spaceBetween: 16,
          slidesPerView: 1.4,
          centeredSlides: true,
        });
      }
    }

    if (document.querySelector('.topBannerSwiper')) {
      let topBannerSwiper, swiperBannerBool;
      const breakPoint = 768;
      const loopBool = document.querySelectorAll('.topBannerItem').length > 1;
      if (breakPoint < window.innerWidth) {
        swiperBannerBool = false;
      } else {
        createSwiper();
        swiperBannerBool = true;
      }
      window.addEventListener(
        'resize',
        () => {
          if (breakPoint < window.innerWidth && swiperBannerBool) {
            topBannerSwiper.destroy(false, true);
            swiperBannerBool = false;
          } else if (breakPoint >= window.innerWidth && !swiperBannerBool) {
            createSwiper();
            swiperBannerBool = true;
          }
        },
        false
      );
      function createSwiper() {
        topBannerSwiper = new Swiper('.topBannerSwiper', {
          loop: loopBool,
          speed: 1500,
          spaceBetween: 16,
          slidesPerView: 1.2,
          centeredSlides: true,
        });
      }
    }

    if (document.querySelector('.eventKVSwiper')) {
      const eventKVNav = document.querySelectorAll('.eventKVNav__item');
      const loopBool = document.querySelectorAll('.eventKVItem').length > 1;

      if (!loopBool) {
        document.querySelector('.eventKVNav').style.display = 'none';
      }

      const eventKVSwiper = new Swiper('.eventKVSwiper', {
        // Optional parameters
        loop: loopBool,
        speed: 1500,
        autoplay: {
          delay: 5000,
        },
      });
      eventKVSwiper.on('activeIndexChange', function (e) {
        document.querySelector('.eventKVNav__item.Act').classList.remove('Act');
        eventKVNav[eventKVSwiper.realIndex].classList.add('Act');
      });
    }

    if (document.querySelector('.movieSwiper')) {
      const movieSlideNum = document.querySelectorAll('.movieSlide').length;
      const titles = document.querySelector('.eventMovieBodyTitle').querySelectorAll('span');
      const pageNum = document.querySelector('.eventMovieNavPager');
      const loopBool = movieSlideNum > 1;
      pageNum.innerText = '1/' + movieSlideNum;
      titles[0].classList.add('Act');

      if (!loopBool) {
        document.querySelector('.eventMovieNav').style.visibility = 'hidden';
      }
      const movieSwiper = new Swiper('.movieSwiper', {
        // Optional parameters
        loop: loopBool,
        speed: 1500,
        // autoplay: {
        //   delay: 5000,
        // },
        navigation: {
          nextEl: '.eventMovieNavR',
          prevEl: '.eventMovieNavL',
        },
      });
      movieSwiper.on('activeIndexChange', function (e) {
        pageNum.innerText = movieSwiper.realIndex + 1 + '/' + movieSlideNum;
        for (let i = 0; i < titles.length; i++) {
          titles[i].classList.remove('Act');
        }
        titles[movieSwiper.realIndex].classList.add('Act');
      });
    }
  }
}
