import Sticky from 'sticky-js';
import smoothScroll from 'smooth-scroll';
import { lock, unlock } from 'tua-body-scroll-lock';

export default class GuidePageEvent {
  constructor() {
    this.init();
  }
  init() {
    if (!document.querySelector('.guidePage')) return false;

    let smoothScrollObj = document.getElementsByClassName('js-smoothScroll');
    const targetElement = document.querySelector('.p_GlobalMenuWrapInner');
    // const menuToggle = document.querySelector('.p_headerNavToggle');
    // const menuClose = document.querySelector('.p_GlobalMenuClose');
    const menu = document.querySelector('.p_GlobalMenuWrap');
    let option = {
      speed: 10,
      durationMax: 1000,
      durationMin: 800,
      clip: true,
      offset: 0,
      easing: 'easeInOutQuint',
      updateURL: false,
      popstate: false,
    };
    for (let i = 0; i < smoothScrollObj.length; i++) {
      smoothScrollObj[i].addEventListener('click', function (e) {
        let scroll = new smoothScroll('a[href*="#"]', option);

        setTimeout(() => {
          menu.classList.toggle('Act');
          unlock(targetElement);
        }, 100);
      });
    }

    let sticky = new Sticky('[data-sticky]', {});
    const Accordions = document.querySelectorAll('.js-Accordion');
    for (let i = 0; i < Accordions.length; i++) {
      Accordions[i].addEventListener('click', (e) => {
        let Target = e.currentTarget;
        let nextElementSibling = Target.nextElementSibling;
        Target.classList.toggle('Act');
        nextElementSibling.classList.toggle('Act');
      });
    }
  }
}
