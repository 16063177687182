'use strict';
import './structure/adjustVh';
import './structure/adjustViewport';
import InViewEvent from './components/inViewEvent';
import TopPageEvent from './components/topPage';
import AboutPageEven from './components/aboutPage';
import MenuPageEvent from './components/menuPage';
import EventPageEvent from './components/eventPage';
import GuidePageEvent from './components/guidePage';
import SwiperEvent from './components/swiperEvent';
import { lock, unlock } from 'tua-body-scroll-lock';

document.addEventListener('DOMContentLoaded', () => {
  const inViewEvent = new InViewEvent();
  const topPageEvent = new TopPageEvent();
  const aboutPageEven = new AboutPageEven();
  const menuPageEvent = new MenuPageEvent();
  const eventPageEvent = new EventPageEvent();
  const guidePageEvent = new GuidePageEvent();
  const swiperEvent = new SwiperEvent();
  // const header = document.querySelector('.l_headBar');
  // const KVImageBox = document.querySelector('#KVImageBox');

  if (document.querySelector('.js-EmergencyBarClose')) {
    const EmergencyBar = document.querySelector('.p_EmergencyBar');
    const EmergencyBarClose = document.querySelector('.js-EmergencyBarClose');
    const EmergencyModes = document.querySelectorAll('.EmergencyMode');
    EmergencyBarClose.addEventListener('click', () => {
      EmergencyBar.classList.add('Hide');
      for (let i = 0; i < EmergencyModes.length; i++) {
        EmergencyModes[i].classList.remove('EmergencyMode');
      }
    });
  }

  const targetElement = document.querySelector('.p_GlobalMenuWrapInner');
  const menuToggle = document.querySelector('.p_headerNavToggle');
  const menuClose = document.querySelector('.p_GlobalMenuClose');
  const menu = document.querySelector('.p_GlobalMenuWrap');
  menuToggle.addEventListener('click', () => {
    if (menu.classList.contains('Act')) {
      unlock(targetElement);
    } else {
      lock(targetElement);
    }
    menu.classList.toggle('Act');
  });

  menuClose.addEventListener('click', () => {
    if (menu.classList.contains('Act')) {
      unlock(targetElement);
    } else {
      lock(targetElement);
    }
    menu.classList.toggle('Act');
  });
});
