// import animetejs
import anime from 'animejs';
import imagesLoaded from 'imagesloaded';
import { lock, unlock } from 'tua-body-scroll-lock';

export default class TopPageEvent {
  constructor() {
    this.init();
  }
  init() {
    if (!document.querySelector('.topPage')) return false;

    const targetElement = document.querySelector('.p_GlobalMenuBox');

    lock(targetElement);

    const Clip = document.querySelector('.js-TopClip');
    const Menu = document.querySelector('.topMenu');
    const Balls = document.querySelectorAll('.js-Ball');
    const TickerBar = document.querySelector('.p_TickerWrap');
    const HeadBar = document.querySelector('.p_header');

    function ScrollBall() {
      const Val = window.pageYOffset / window.innerHeight;
      // const Scale = 1 - Val;

      if (Val > 0.3) {
        for (let i = 0; i < Balls.length; i++) {
          Balls[i].classList.add('Hide');
          TickerBar.classList.add('Hide');
        }
      } else {
        for (let i = 0; i < Balls.length; i++) {
          Balls[i].classList.remove('Hide');
          TickerBar.classList.remove('Hide');
          // TickerBar.style.width = Scale * 100 + '%';
        }
      }
      // for (let i = 0; i < Balls.length; i++) {
      //   Balls[i].style.transform = 'scale(' + Scale + ') rotate(' + Scale + 'deg)';
      //   Balls[i].style.opacity = Scale;
      //   TickerBar.style.width = Scale * 100 + '%';
      // }
    }

    function ScrollEv() {
      const WinTop = window.pageYOffset / window.innerHeight;
      let WinTopDIff = 100 - WinTop * 100;
      const MenuRect = Menu.getBoundingClientRect();
      const MenuTop = window.pageYOffset + MenuRect.top;
      const Val = window.pageYOffset / MenuTop;
      let round = 100;
      let side = 20;
      let sideVal = 20;
      let roundVal = 100;

      if (window.innerWidth < 768) {
        sideVal = 5;
        roundVal = 50;
      }

      if (WinTop <= 0.5) {
        if (window.innerWidth < 768) {
          side = 10;
          Clip.style.clipPath =
            'inset(15% ' + side + '% 50% ' + side + '% round ' + round + 'vh ' + round + 'vh 0 0)';
          return false;
        } else {
          side = 20;
        }
      } else if (WinTop < 1 && WinTop > 0.5) {
        if (window.innerWidth < 768) {
          side = 2;
        } else {
          side = 2;
        }
      } else if (WinTop >= 1) {
        if (Val > 1.25) {
          side = sideVal / (Val * 5);
          round = roundVal / (Val * 5);
        } else {
          side = sideVal * Val;
          if (side > 25) {
            side = 25;
          }
        }
      }

      round = Math.floor(round);
      side = Math.floor(side);

      if (round < 10) {
        round = 10;
      }
      if (round > 90) {
        round = 90;
      }
      if (WinTopDIff < 0) {
        WinTopDIff = 0;
      }

      Clip.style.clipPath =
        'inset(10% ' + side + '% 50% ' + side + '% round ' + round + 'vh ' + round + 'vh 0 0)';
    }

    const returnValue = (min = 1, max = 10, isMinus = false, before = 0) => {
      let num = Math.random() * (max - min) + min; //Math.floor(Math.random() * max) + min;
      if (isMinus) {
        num *= Math.floor(Math.random() * 2) == 1 ? 1 : -1;
        // num * -1;
      }
      // if (before > 0 && before !== 0) {
      //   num * -1;
      //   return num;
      // }
      return num;
    };

    const animeBallEvent = (BallImages, BallRota = 0, ballNum = 0) => {
      let scaleRandom = returnValue(98, 100, false, -1) / 100;
      let rotateRandom = returnValue(10, 20, true, BallRota);
      let rotateRandomEnd = rotateRandom;
      let BallAnime = anime.timeline({
        targets: BallImages,
      });
      BallAnime.add({
        duration: 1500,
        scale: [1, scaleRandom],
        // rotate: [BallRota, rotateRandom / 2],
        easing: 'cubicBezier(0.550, 0.085, 0.680, 0.530)',
        complete: function (anim) {},
      }).add({
        duration: 1500,
        scale: [scaleRandom, 1],
        // rotate: [rotateRandom / 2, BallRota],
        easing: 'cubicBezier(0.250, 0.460, 0.450, 0.940)',
        complete: (anim) => {
          if (ballNum === 1) {
            Ball1Rota = BallRota;
          }
          if (ballNum === 2) {
            Ball2Rota = BallRota;
          }
          if (ballNum === 3) {
            Ball3Rota = BallRota;
          }
          if (ballNum === 4) {
            Ball4Rota = BallRota;
          }
        },
      });
    };

    const Ball1 = document.querySelector('.Ball1');
    const Ball1Inner = Ball1.querySelector('.topKVBallInner');
    const Ball1Images = Ball1.querySelectorAll('.m_image');
    let Ball1Rota = 0;
    let currentBall1 = 0;
    const Ball1EVent = () => {
      setTimeout(() => {
        let prevBall = currentBall1 + 1;
        if (prevBall >= Ball1Images.length) {
          prevBall = 0;
        }
        Ball1Images[currentBall1].classList.remove('On');
        Ball1Images[prevBall].classList.add('On');
        animeBallEvent(Ball1Inner, Ball1Rota, 1);
        currentBall1 = prevBall;
        Ball1EVent();
      }, Math.random() * (8000 - 7500) + 7500);
    };

    const Ball2 = document.querySelector('.Ball2');
    const Ball2Inner = Ball2.querySelector('.topKVBallInner');
    const Ball2Images = Ball2.querySelectorAll('.m_image');
    let Ball2Rota = 0;
    let currentBall2 = 0;
    const Ball2EVent = () => {
      setTimeout(() => {
        let prevBall = currentBall2 + 1;
        if (prevBall >= Ball2Images.length) {
          prevBall = 0;
        }
        Ball2Images[currentBall2].classList.remove('On');
        Ball2Images[prevBall].classList.add('On');
        animeBallEvent(Ball2Inner, Ball2Rota, 2);
        currentBall2 = prevBall;
        Ball2EVent();
      }, Math.random() * (8000 - 7500) + 7500);
    };

    const Ball3 = document.querySelector('.Ball3');
    const Ball3Inner = Ball3.querySelector('.topKVBallInner');
    const Ball3Images = Ball3.querySelectorAll('.m_image');
    let Ball3Rota = 0;
    let currentBall3 = 0;
    const Ball3EVent = () => {
      setTimeout(() => {
        let prevBall = currentBall3 + 1;
        if (prevBall >= Ball3Images.length) {
          prevBall = 0;
        }
        Ball3Images[currentBall3].classList.remove('On');
        Ball3Images[prevBall].classList.add('On');
        animeBallEvent(Ball3Inner, Ball3Rota, 3);
        currentBall3 = prevBall;
        Ball3EVent();
      }, Math.random() * (8000 - 7500) + 7500);
    };

    const Ball4 = document.querySelector('.Ball4');
    const Ball4Inner = Ball4.querySelector('.topKVBallInner');
    const Ball4Images = Ball4.querySelectorAll('.m_image');
    let Ball4Rota = 0;
    let currentBall4 = 0;
    const Ball4EVent = () => {
      setTimeout(() => {
        let prevBall = currentBall4 + 1;
        if (prevBall >= Ball4Images.length) {
          prevBall = 0;
        }
        Ball4Images[currentBall4].classList.remove('On');
        Ball4Images[prevBall].classList.add('On');
        animeBallEvent(Ball4Inner, Ball4Rota, 4);
        currentBall4 = prevBall;
        Ball4EVent();
      }, Math.random() * (8000 - 7500) + 7500);
    };

    const loadBool = sessionStorage.getItem('load');
    const loaderWrap = document.querySelector('.p_loaderWrap');

    if (loadBool) {
      loaderWrap.classList.add('Visible');
      setTimeout(() => {
        onComp();
      }, 1200);
    } else {
      imagesLoaded(document.querySelector('.topKV'), () => {
        opAnime();
        sessionStorage.setItem('load', 'true');
      });
    }

    function opAnime() {
      const loaderArch = document.querySelector('.p_loaderArchInner');
      let loaderArchAnime = anime.timeline({
        targets: loaderArch,
      });
      loaderArchAnime
        .add({
          delay: 100,
          duration: 2000,
          scale: [1, 3],
          marginTop: '40%',
          easing: 'cubicBezier(0.645, 0.045, 0.265, 1.030)',
          complete: function (anim) {},
        })
        .add({
          delay: 500,
          duration: 1000,
          scale: [3, 10],
          easing: 'cubicBezier(0.250, 0.460, 0.450, 0.940)',
          complete: function (anim) {},
        });

      const loaderLogo = document.querySelector('.p_loaderLogoBox');
      let loaderLogoAnime = anime.timeline({
        targets: loaderLogo,
      });
      loaderLogoAnime
        .add({
          delay: 700,
          duration: 1550,
          translateY: ['10%', 0],
          opacity: [0, 1],
          easing: 'cubicBezier(0.250, 0.460, 0.450, 0.940)',
          complete: function (anim) {},
        })
        .add({
          delay: 200,
          duration: 1100,
          translateY: [0, '-20%'],
          opacity: [1, 0],
          easing: 'cubicBezier(0.645, 0.045, 0.265, 1.030)',
          complete: function (anim) {},
        });

      let loaderWrapAnime = anime.timeline({
        targets: loaderWrap,
      });
      loaderWrapAnime.add({
        duration: 450,
        delay: 3500,
        opacity: [1, 0],
        easing: 'cubicBezier(0.470, 0.000, 0.745, 0.715)',

        complete: function (anim) {
          onComp();
        },
      });
    }

    function onComp() {
      loaderWrap.classList.add('Hide');
      anime({
        targets: document.querySelector('.p_TickerWrap'),
        width: [0, '100%'],
        duration: 550,
        easing: 'cubicBezier(0.190, 1.000, 0.220, 1.000)',
      });
      anime({
        targets: document.querySelector('.BallTodayLive'),
        scale: [0, 1],
        opacity: [0, 1],
        duration: 550,
        easing: 'cubicBezier(0.540, 0.060, 0.000, 1.040)',
      });
      anime({
        targets: document.querySelector('.topKVDownScroll'),
        scale: [0, 1],
        duration: 550,
        easing: 'cubicBezier(0.540, 0.060, 0.000, 1.040)',
      });

      anime({
        targets: Ball1Inner,
        scale: [0, 1],
        rotate: [returnValue(3, 30, true, 0), 0],
        duration: 950,
        delay: Math.random() * (200 - 0) + 0 + 50,
        easing: 'cubicBezier(0.540, 0.060, 0.000, 1.040)',
        complete: function (anim) {
          setTimeout(() => {
            animeBallEvent(Ball1Inner, Ball1Rota, 1);
            Ball1Images[currentBall1].classList.remove('On');
            Ball1Images[1].classList.add('On');
            currentBall1 = 1;
            Ball1EVent();
          }, Math.random() * (1000 - 500) + 500);
        },
      });

      anime({
        targets: Ball2Inner,
        scale: [0, 1],
        rotate: [returnValue(3, 30, true, 0), 0],
        delay: Math.random() * (200 - 0) + 0 + 50,
        duration: 950,
        easing: 'cubicBezier(0.540, 0.060, 0.000, 1.040)',
        complete: function (anim) {
          setTimeout(() => {
            animeBallEvent(Ball2Inner, Ball2Rota, 2);
            Ball2Images[currentBall2].classList.remove('On');
            Ball2Images[1].classList.add('On');
            currentBall2 = 1;
            Ball2EVent();
          }, Math.random() * (1000 - 500) + 500);
        },
      });

      anime({
        targets: Ball3Inner,
        scale: [0, 1],
        rotate: [returnValue(3, 30, true, 0), 0],
        delay: Math.random() * (200 - 0) + 0 + 50,
        duration: 950,
        easing: 'cubicBezier(0.540, 0.060, 0.000, 1.040)',
        complete: function (anim) {
          setTimeout(() => {
            Ball3Images[currentBall3].classList.remove('On');
            Ball3Images[1].classList.add('On');
            animeBallEvent(Ball3Inner, Ball3Rota, 3);
            currentBall3 = 1;
            Ball3EVent();
          }, Math.random() * (1000 - 500) + 500);
        },
      });

      anime({
        targets: Ball4Inner,
        scale: [0, 1],
        rotate: [returnValue(3, 30, true, 0), 0],
        delay: Math.random() * (200 - 0) + 0 + 50,
        duration: 950,
        easing: 'cubicBezier(0.540, 0.060, 0.000, 1.040)',
        complete: function (anim) {
          setTimeout(() => {
            Ball4Images[currentBall4].classList.remove('On');
            Ball4Images[1].classList.add('On');
            animeBallEvent(Ball4Inner, Ball4Rota, 4);
            currentBall4 = 1;
            Ball4EVent();
          }, Math.random() * (1000 - 500) + 500);
        },
      });

      setTimeout(() => {
        HeadBar.classList.remove('Hide');
      }, 800);

      setTimeout(() => {
        unlock(targetElement);
        opAnimeDone();
      }, 1000);
    }

    function opAnimeDone() {
      window.addEventListener('scroll', () => {
        ScrollEv();
        ScrollBall();
      });
    }

    ScrollEv();
    // ScrollBall();
    window.addEventListener('resize', () => {
      if (window.innerWidth < 768) {
        posCalc(Ball3, Ball1, 275, 2.13, 2.13);
        posCalc(Ball1, Ball4, 255, 0.29, 0.3);
        posCalc(Ball1, Ball2, 310, 1, 0.52);
      } else {
        posCalc(Ball3, Ball4, 310, 0.85, 0.8);
        posCalc(Ball4, Ball1, 330, 1.2, 1);
        posCalc(Ball1, Ball2, 350, 1, 1);
      }
    });

    if (window.innerWidth < 768) {
      posCalc(Ball3, Ball1, 275, 2.13, 2.13);
      posCalc(Ball1, Ball4, 255, 0.29, 0.3);
      posCalc(Ball1, Ball2, 310, 1, 0.52);
    } else {
      posCalc(Ball3, Ball4, 310, 0.85, 0.8);
      posCalc(Ball4, Ball1, 330, 1.2, 1);
      posCalc(Ball1, Ball2, 350, 1, 1);
    }
    function posCalc(ball, ball2, angle, diffX, diffY) {
      let hypo1 = Math.hypot(ball.clientWidth, ball.clientWidth) / 2;
      // let hypo2 = Math.hypot(ball2.clientWidth, ball2.clientHeight) / 2;
      // let hypo1 = ball.clientWidth;
      let hypo2 = 0; //ball2.clientWidth / 2;
      let distance = hypo1 * 1.35 + hypo2;
      const x2 = ball.offsetLeft + distance * Math.cos(angle * (Math.PI / 180)) * diffX;
      const y2 = ball.offsetTop + distance * Math.sin(angle * (Math.PI / 180)) * diffY;
      ball2.style.left = x2 + 'px';
      ball2.style.top = y2 + 'px';
    }
  }
}
