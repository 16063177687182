export default class MenuPageEvent {
  constructor() {
    this.init();
  }
  init() {
    if (!document.querySelector('.menuPage')) return false;
    const Clip = document.querySelector('.js-TopClip');
    // Clip.style.clipPath = 'inset(0 20% 0 20% round 100vh 100vh 0 0)';

    function ScrollEv() {
      let Val = window.pageYOffset / window.innerHeight;
      let sideVal = 20;
      if (window.innerWidth < 768) {
        sideVal = 8;
      }
      let round = Math.floor(100 - Val * 100);
      let side = Math.floor(sideVal - 80 * Val);

      if (round < 10) {
        round = 10;
      }
      if (round > 90) {
        round = 90;
      }
      if (side < 0) {
        side = 0;
      }

      Clip.style.clipPath =
        'inset(7% ' + side + '% 50% ' + side + '% round ' + round + 'vh ' + round + 'vh 0 0)';
    }
    window.addEventListener('scroll', () => {
      ScrollEv();
    });
  }
}
