import Sticky from 'sticky-js';
import { lock, unlock } from 'tua-body-scroll-lock';

export default class EventPageEvent {
  constructor() {
    this.init();
  }
  init() {
    if (document.querySelector('.calPage')) {
      const Cal = document.querySelector('.calBoardCards');
      const List = document.querySelector('.calListCards');
      const CalButton = document.querySelector('.calBoardSwitch__item.Cal');
      const ListButton = document.querySelector('.calBoardSwitch__item.List');
      CalButton.addEventListener('click', () => {
        Cal.classList.add('Act');
        List.classList.remove('Act');
        CalButton.classList.add('Act');
        ListButton.classList.remove('Act');
      });
      ListButton.addEventListener('click', () => {
        Cal.classList.remove('Act');
        List.classList.add('Act');
        CalButton.classList.remove('Act');
        ListButton.classList.add('Act');
      });
    }

    if (!document.querySelector('.eventDetailPage')) return false;

    let sticky = new Sticky('[data-sticky]', {});
    const Accordions = document.querySelectorAll('.js-Accordion');
    for (let i = 0; i < Accordions.length; i++) {
      Accordions[i].addEventListener('click', (e) => {
        let Target = e.currentTarget;
        let nextElementSibling = Target.nextElementSibling;
        Target.classList.toggle('Act');
        nextElementSibling.classList.toggle('Act');
      });
    }

    let targetElement;
    let modalType = '';
    const closeBtns = document.querySelectorAll('.l_modalClose');
    for (let i = 0; i < closeBtns.length; i++) {
      closeBtns[i].addEventListener('click', (e) => {
        let Target = e.currentTarget;
        let modal = Target.closest('.l_modalWrap');
        modal.classList.remove('Act');

        if (modalType) {
          videoControl('pauseVideo');
          function videoControl(action) {
            var $playerWindow = document.getElementById('video-iframe').contentWindow;
            $playerWindow.postMessage('{"event":"command","func":"' + action + '","args":""}', '*');
          }
        }

        unlock(targetElement);
        targetElement = null;
      });
    }
    const closeBgBtns = document.querySelectorAll('.l_modalCloseBg');
    for (let i = 0; i < closeBgBtns.length; i++) {
      closeBgBtns[i].addEventListener('click', (e) => {
        let Target = e.currentTarget;
        let modal = Target.closest('.l_modalWrap');
        modal.classList.remove('Act');

        if (modalType) {
          videoControl('pauseVideo');
          function videoControl(action) {
            var $playerWindow = document.getElementById('video-iframe').contentWindow;
            $playerWindow.postMessage('{"event":"command","func":"' + action + '","args":""}', '*');
          }
        }
        unlock(targetElement);
        targetElement = null;
      });
    }

    if (document.querySelector('.js-modalYoutube')) {
      const modalYoutube = document.querySelectorAll('.js-modalYoutube');
      modalYoutube[0].addEventListener('click', (e) => {
        let target = document.querySelector('.Youtube');
        target.classList.add('Act');
        targetElement = target.querySelector('.l_modalContent');
        modalType = 'Movie';
        lock(targetElement);
      });
      modalYoutube[1].addEventListener('click', (e) => {
        let target = document.querySelector('.Youtube');
        target.classList.add('Act');
        targetElement = target.querySelector('.l_modalContent');
        modalType = 'Movie';
        lock(targetElement);
      });
    }

    if (document.querySelector('.js-modalLiveReserve')) {
      const modalLiveReserve = document.querySelectorAll('.js-modalLiveReserve');
      modalLiveReserve[0].addEventListener('click', (e) => {
        let target = document.querySelector('.LiveReserve');
        target.classList.add('Act');
        targetElement = target.querySelector('.l_modalContent');
        lock(targetElement);
      });
      modalLiveReserve[1].addEventListener('click', (e) => {
        let target = document.querySelector('.LiveReserve');
        target.classList.add('Act');
        targetElement = target.querySelector('.l_modalContent');
        lock(targetElement);
      });
    }

    if (document.querySelector('.js-modalRestReserve')) {
      const modalRestReserve = document.querySelectorAll('.js-modalRestReserve');
      modalRestReserve[0].addEventListener('click', (e) => {
        let target = document.querySelector('.RestReserve');
        target.classList.add('Act');
        targetElement = target.querySelector('.l_modalContent');
        lock(targetElement);
      });
      modalRestReserve[1].addEventListener('click', (e) => {
        let target = document.querySelector('.RestReserve');
        target.classList.add('Act');
        targetElement = target.querySelector('.l_modalContent');
        lock(targetElement);
      });
    }
  }
}
