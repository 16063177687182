import inView from 'in-view';

export default class InViewEvent {
  constructor() {
    this.init();
  }
  init() {
    inView.threshold(0.2);
    inView('.js-inView')
      .on('enter', function (el) {
        el.classList.add('js-inViewAct');
      })
      .on('exit', (el) => {
        // el.classList.remove('js-inViewAct');
      });

    inView('.js-inViewState')
      .on('enter', function (el) {
        el.classList.add('js-inViewStateAct');
      })
      .on('exit', (el) => {
        // el.classList.remove('js-inViewStateAct');
      });
  }
}
